import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import GiftCardBackground from "../components/giftcardbackground";
import settings from "../../settings";

// images
const topbanner = `${settings.IMAGES_BASE_URL}/v2/images/specialoffers/desktop/bmw-specials-banner.jpg`;

const SpecialOffersLinkedin = () => {
  const getFirstCard = () => {
    const today = new Date();
    const julyPromotions =
      today >= new Date(2023,6,1) &&
      today < new Date(2023,8,10);
    if (julyPromotions) {
      return <></>;
    }  else {
      return <></>;
    }
  };
  return (
    <Layout>
      <SEO
        title="BMW Performance Driving School | Gift Cards"
        description="Frequently asked questions for new BMW owners who qualify for the BMW Performance Center Delivery Program and are ready to fully experience BMW culture!"
      />
      <div className="giftcardpage">
        <GiftCardBackground
          imageDesktop={topbanner}
          imageMobile={topbanner}
          imageAlt="BMW Gift Card Top Banner"
        />

        <section className="performance-center">
          <div className="container">
            <div className="performance-center__header">
              <h2>Special Offers</h2>
            </div>
          </div>
          {getFirstCard()}
        </section>
      </div>
    </Layout>
  );
};

export default SpecialOffersLinkedin;
